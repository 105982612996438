<template>
    <div class="feature-anchor-links-block">
        <div class="feature-anchor-links-content observed" :class="{hidden: !visible}">
            <div v-if="external_button_link" class="button-box button-box--link button-box--newest">
                <a v-if="!getInternalLink(external_button_link)" :href="external_button_link" class="cta"><span>{{ external_button_text }}</span></a>
                <router-link :to="external_button_link" v-else class="cta"><span>{{ external_button_text }}</span></router-link>
            </div>
            <button v-if="button_text_one" @click="scrollToElement('feature-block-1')" class="button-box cta"><span>{{ button_text_one }}</span></button>
            <button v-if="button_text_two" @click="scrollToElement('feature-block-2')" class="button-box cta"><span>{{ button_text_two }}</span></button>
            <button v-if="button_text_three" @click="scrollToElement('feature-block-3')" class="button-box cta"><span>{{ button_text_three }}</span></button>
            <button v-if="button_text_four" @click="scrollToElement('feature-block-4')" class="button-box cta"><span>{{ button_text_four }}</span></button>
        </div>
    </div>
</template>

<script>

import SectionMixin from '@/mixins/sectionMixin'

export default {
    name:'feature-anchor-links',
    mixins: [SectionMixin],
    props: {
        button_text_one: String,
        button_text_two: String,
        button_text_three: String,
        button_text_four: String,
        external_button_link: String,
        external_button_text: String
    },
    data: () => {
        return {
            visible: false,
        }
    },
    mounted() {
        
    },
    methods: {
        show() {
            console.log("show")
            this.visible = true
        },
        scrollToElement(id) {
            const elementId = document.getElementById(id);
            elementId.scrollIntoView({ behavior: 'smooth' });
        }
    }

}
</script>

<style lang="less">
@import "../../../styles/vars.less";

.feature-anchor-links-block{
    padding: 90px 0;
    background-color: @blue-light;
    .feature-anchor-links-content{
        position: relative;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        padding-left: 8px;
        gap: 24px 32px;
        max-width: 1024px;
        z-index: 1;
        margin: auto;
        transition: transform 1s @custom-ease-1, opacity 1s @custom-ease-1;
        &.hidden{
            opacity: 0;
        }
        .cta{
            padding:13px 30px;
            span::after,&::after{
                content: none;
            }
        }
        .button-box{
            grid-column: span 2;
            &:nth-last-child(2):nth-child(3n+1){
                grid-column-end: 4;
            }
            &:nth-child(1) {
                grid-column: e("3 / 5");
                grid-row: 1;
            }
            &:nth-child(2){
                grid-row: 1;
                grid-column: e("1 / 3");
            }

            &--link .cta{
                height: 100%;
                width: 100%;
                align-content: center;
                text-align: center;
            }
            &--newest{
                position: relative;
                overflow: initial;
                &.cta,.cta{
                    background:#FFB3AB;
                }
                &::after{
                    position: absolute;
                    content: 'Nuevo';
                    font-family: gilroy-bold;
                    top: -10px;
                    right: 0;
                    padding: 5px 10px;
                    border-radius: 12px;
                    background: #F0DFFE;
                    line-height: 1;
                    font-size: 0.625rem;
                    z-index: 2;
                    transform: none;
                    height: auto;
                    width: auto;
                    pointer-events: none;
                    cursor: pointer;
                }
            }
        }
    }
    @media screen and (max-width: @tablet){
    padding: 24px 0;
        .feature-anchor-links-content{
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            grid-auto-flow: row;
            gap: 16px;
            padding: 0 8%;
            max-width: 760px;

            .cta{
                padding: 12px;
            }
            .button-box{
                font-size: 0.75rem;
                grid-column: initial;
                &:nth-child(1){
                    grid-column: span 2;
                }
                &:nth-child(2){
                    grid-column: initial;
                    grid-row: initial;
                }
                &:nth-last-child(2):nth-child(3n+1){
                    grid-column-end: initial;
                }
            }
        }
    }
}
</style>