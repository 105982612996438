<template>
    <div class="feature-view">
        <navigation ref="navigation" />
        <modal-cookies ref="modal-cookies"/>
        <header-section 
            class="features"
            :subtitle="content.attributes.introduction"
            :link_text="content.attributes.link_text"
            :fullScreen="content.attributes.full_screen"
            :videoUrl="content.attributes.media.data ? getAssetUrl(content.attributes.media.data.attributes.url) : ''"
        />   
        <div v-for="(c,i) in content.attributes.content" :key="i" :id="'feature-block-'+i" class="block">
            <feature-anchor-links
                v-if="c.__component == 'features.anchor-links'"
                :button_text_one="c.button_text_one"
                :button_text_two="c.button_text_two"
                :button_text_three="c.button_text_three"
                :button_text_four="c.button_text_four"
                :external_button_link="c.external_button_link"
                :external_button_text="c.external_button_text"
            />
            <feature-full-image-block 
                v-if="c.__component == 'features.fullimage' && c.media.data"
                :imgUrl="imageFormat(c.media.data.attributes.ext) ? getAssetUrl(c.media.data.attributes.url) : null"
                :videoUrl="videoFormat(c.media.data.attributes.ext) ? getAssetUrl(c.media.data.attributes.url) : null"
                :title="c.title"
                :text="c.text"
            />
            <feature-full-video-block
                v-if="c.__component == 'features.full-video' && c.videoId"  
                :videoId="c.videoId"
            />
            <feature-phone-block
                v-if="c.__component == 'features.phone' && c.media.data"
                :title="c.title"
                :text="c.text"
                :media="c.media"
            />
            <feature-items-block
                v-if="c.__component == 'features.cards'"
                :title="c.title"
                :subTitle="c.subTitle"
                :items="c.content"
                :alternate="c.alternate"
            />
            <feature-cards-block
                v-if="c.__component == 'features.list' && c.media.data"
                :imgUrl="imageFormat(c.media.data.attributes.ext) ? getAssetUrl(c.media.data.attributes.url) : null"
                :videoUrl="videoFormat(c.media.data.attributes.ext) ? getAssetUrl(c.media.data.attributes.url) : null"
                :title="c.title"
                :text="c.text"
                :cards="c.content"
                :alternate="c.alternate"
            />
            <feature-splited-image-block
                v-if="c.__component == 'features.bigtitle' && c.image"
                :imgUrl="c.image.data.attributes.ext == '.jpg' || c.image.data.attributes.ext == '.png' ? getAssetUrl(c.image.data.attributes.url) : null"
                :videoUrl="c.image.data.attributes.ext == '.mp4' || c.image.data.attributes.ext == '.gif' ? getAssetUrl(c.image.data.attributes.url) : null"
                :title="c.title"
                :description="c.description"
                :titleBgColor="c.titleBgColor"
            />
            <feature-splited-text-block
                v-if="c.__component == 'features.splited-text' && c.title"
                :title="c.title"
                :subTitle="c.subTitle"
                :description="c.description"
            />
            <feature-shift-media-block
                v-if="c.__component == 'features.shift-media' && c.media"
                :imgUrl="c.media.data.attributes.ext == '.jpg' || c.media.data.attributes.ext == '.png' ? getAssetUrl(c.media.data.attributes.url) : null"
                :videoUrl="c.media.data.attributes.ext == '.mp4' || c.media.data.attributes.ext == '.gif' ? getAssetUrl(c.media.data.attributes.url) : null"
                :title="c.title"
                :description="c.description"
                :titleBgColor="c.titleBgColor"
                :mediaBgColor="c.mediaBgColor"
                :buttonText="c.buttonText"
                :buttonLink="c.buttonLink"
                :shift="c.shift"
                :title_question="c.title_question"
                :text_keyword="c.text_keyword"

            />
            <feature-splited-form-block
                v-if="c.__component == 'features.splited-form' && c.media"
                :imgUrl="c.media.data.attributes.ext == '.jpg' || c.media.data.attributes.ext == '.png' ? getAssetUrl(c.media.data.attributes.url) : null"
                :videoUrl="c.media.data.attributes.ext == '.mp4' || c.media.data.attributes.ext == '.gif' ? getAssetUrl(c.media.data.attributes.url) : null"
                :title="c.title"
                :description="c.description"
                :disclaimer="c.disclaimer"
            />
            <feature-impact-block
                v-if="c.__component == 'features.impact' && c.title"
                :title="c.title"
                :description="c.description"
                :link="c.link"
                :link_text="c.link_text"
                :cards="c.cards"
                :color_type="c.color_type"
                :tree_type="c.tree_type"
            />
            <feature-vertical-block
                v-if="c.__component == 'features.vertical-media' && c.media"
                :title="c.title"
                :description="c.description"
                :link_text="c.link_text"
                :imgUrl="c.media.data.attributes.ext == '.jpg' || c.media.data.attributes.ext == '.png' ? getAssetUrl(c.media.data.attributes.url) : null"
                :videoUrl="c.media.data.attributes.ext == '.mp4' || c.media.data.attributes.ext == '.gif' ? getAssetUrl(c.media.data.attributes.url) : null"
            />

        </div>
        <footer-section />
    </div>
</template>

<script>
    // Webgl
    import WebglManager from '@/glxp/webglManager'

    // Mixins
    import ViewMixin from "@/mixins/viewMixin"

    // Components Global
    import Navigation from "@/components/navigation"
    import ModalCookies from "@/components/global/ModalCookies"
    import Footer from "@/components/global/footer"
    import Header from "@/components/global/header"

    // Components Feature
    import FeatureFullImageBlock from "@/components/feature/feature-full-image-block"
    import FeatureFullVideoBlock from "@/components/feature/feature-full-video-block"
    import FeaturePhoneBlock from "@/components/feature/feature-phone-block"
    import FeatureCardsBlock from "@/components/feature/feature-cards-block"
    import FeatureItemsBlock from "@/components/feature/feature-items-block"
    import FeatureSplitedImageBlock from "@/components/feature/feature-splited-image-block"
    import FeatureSplitedTextBlock from "@/components/feature/feature-splited-text-block"
    import FeatureShiftMediaBlock from "@/components/feature/feature-shift-media-block"
    import FeatureSplitedFormBlock from "@/components/feature/feature-splited-form-block"
    import FeatureImpactBlock from "@/components/feature/feature-impact-block"
    import FeaturesAnchorLinks from "@/components/feature/feature-anchor-links"
    import FeaturesVerticalBlock from "@/components/feature/feature-vertical-block"

    export default {
        name: 'feature',
        mixins: [ViewMixin],
        data: () => {
            return {
                visible: true,
            }
        },
        components: {
            'navigation': Navigation,
            'modal-cookies': ModalCookies,
            'footer-section': Footer,
            'header-section': Header,
            'feature-full-image-block': FeatureFullImageBlock,
            'feature-full-video-block':FeatureFullVideoBlock,
            'feature-phone-block': FeaturePhoneBlock,
            'feature-cards-block': FeatureCardsBlock,
            'feature-items-block': FeatureItemsBlock,
            'feature-splited-image-block': FeatureSplitedImageBlock,
            'feature-splited-text-block': FeatureSplitedTextBlock,
            'feature-shift-media-block': FeatureShiftMediaBlock,
            'feature-splited-form-block': FeatureSplitedFormBlock,
            'feature-impact-block': FeatureImpactBlock,
            'feature-anchor-links': FeaturesAnchorLinks,
            'feature-vertical-block': FeaturesVerticalBlock,
        },
        metaInfo() {
            return{
            meta: [
                { vmid: 'og:title', property: 'og:title', name: 'twitter:title', content: this.ogTitle },
                { vmid: 'og:description', property: 'og:description', name: 'twitter:description', content: this.ogDescription },
                { vmid: 'og:image', property: 'og:image', name: 'twitter:image', itemprop:'image', content: this.ogImage },
                { vmid: 'og:image:secure_url', property: 'og:image:secure_url', name: 'twitter:image', itemprop:'image', content: this.ogImage },
                { vmid: 'og:image:alt',  property: 'og:image:alt', content: this.ogImageAlt },
                { vmid: 'og:url', property: 'og:url', content: this.ogImageUrl },
                ]
            }
        }, 

        updated(){
            this.$nextTick(() => {
                this.updateViewport()
                this.initIntersectionObserver()
            })
        },

        mounted() {
            this.$nextTick(() => {
                this.glxp = WebglManager.getScene('main')
                WebglManager.loadScene('main').then(() => {
                    WebglManager.activate('main')
                    this.glxp.skipIntro()
                    this.show()
                })
                this.$refs.navigation.activate("/features")
            })
        },

        beforeDestroy() {

        },

        methods: {
            show() {
                this.$refs.navigation.hidden = false
                this.$refs.navigation.forcedLight = true
            },
            imageFormat(rawFormat){
                switch (true) {
                    case (rawFormat === '.png'):
                        return 1
                    case (rawFormat === '.jpg'):
                        return 1
                    case (rawFormat === '.jpeg'):
                        return 1
                    case (rawFormat === '.svg'):
                        return 1
                    case (rawFormat === '.gif'):
                        return 1
                    default:
                        break;
                }
            },
            videoFormat(rawFormat){
                switch (true) {
                    case (rawFormat === '.mp4'):
                        return 1
                    case (rawFormat === '.mpeg'):
                        return 1
                    default:
                        break;
                }
            }
        },
        computed:{
            content(){
                return this.$store.state.page.featuress[this.$route.params.id]
            },
            ogTitle(){
                return this.content.attributes.SEO.meta[0].content
            },
            ogDescription(){
                return this.content.attributes.SEO.meta[1].content
            },
            ogImage(){
                return this.content.attributes.SEO.meta[2].content
            },
            ogImageAlt(){
                return this.content.attributes.SEO.meta[3].content
            },
            ogImageUrl(){
                return this.content.attributes.SEO.meta[4].content
            },
        }
    }
</script>

<style lang="less" scoped>
	@import "../styles/vars.less";

	.feature-view {
		@media screen and (max-width: @tablet) {
			background-color: @white-light;
		}
	}
</style>